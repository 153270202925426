import '../css/main.scss';

import 'jquery-validation';
$.validator.unobtrusive = require('jquery-validation-unobtrusive');
import 'jssocials';

import 'lazysizes';
import { InitialiseForElement as AjaxFormInitialise } from './components/PartialHost';
import { SetActiveStyleSheet } from '@dbs/style-switcher';
import { readCookie, createCookie } from './Components/Cookies';
import { Modal, Popover } from 'bootstrap';

import { InitialiseGraph } from "./components/WaitingGraph";

import Scrollbar from "smooth-scrollbar";

document.body.classList.remove("no-js");
document.body.classList.add("js");

$(function ()
{
    const pageContent = document.getElementById("page-content");
    const isPatientPage = pageContent?.classList.contains("patient-content") ?? false;
    const isGpPage = pageContent?.classList.contains("gp-content") ?? false;

    window.dataLayer = window.dataLayer || [];
    if (isPatientPage || isGpPage)
    {
        window.dataLayer.push({ "page_type": isPatientPage ? "Public View" : "GP View" });
    }

    const graphs = document.querySelectorAll("canvas.waiting-graph");
    for (let i = 0; i < graphs.length; i++)
    {
        InitialiseGraph(graphs[i] as HTMLCanvasElement);
    }

    const waitingOptions = document.querySelectorAll("form.waiting-times-form select");
    for (let i = 0; i < waitingOptions.length; i++)
    {
        waitingOptions[i].addEventListener("change", () =>
        {
            let allOptionsSet = true;
            let options: Record<string, string> = {};

            for (let j = 0; j < waitingOptions.length; j++)
            {
                const dropdown = waitingOptions[j] as HTMLSelectElement;
                allOptionsSet &&= !!dropdown.value;
                options[dropdown.name] = dropdown.children[dropdown.selectedIndex].textContent ?? "";
            }

            if (allOptionsSet)
            {
                if (isPatientPage && options["Speciality"])
                {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({ "dropdown_label": options["Speciality"] });
                    window.dataLayer.push({ "event": "dropdown" });
                }
                else if (isGpPage && options["Borough"] && options["Speciality"])
                {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({ "dropdown_label": `${options["Borough"]} - ${options["Speciality"]}` });
                    window.dataLayer.push({ "event": "dropdown" });
                }
                (waitingOptions[i] as HTMLSelectElement).form?.submit();
            }
        });
    }

    function setupTravelForm()
    {
        const travelOptions = document.querySelectorAll("form.travel-time-form select");
        for (let i = 0; i < travelOptions.length; i++)
        {
            const item = travelOptions[i] as HTMLSelectElement;
            item.addEventListener("change", () =>
            {
                if (item.value === "DepartAt" || item.value === "ArriveBy")
                {
                    jQuery(".travel-time-form .col-md.collapse").addClass("show");
                }
                else
                {
                    jQuery(".travel-time-form .col-md.collapse").removeClass("show");
                }
            });

            const startEvent = document.createEvent("Event");
            startEvent.initEvent("change");
            item.dispatchEvent(startEvent);
        }

        const typeSubmit = document.querySelector("form.travel-time-form input[name=Type]") as HTMLInputElement | null;
        if (typeSubmit)
        {

            const typeOptions = document.querySelectorAll("input[name=tt_type]");
            for (let i = 0; i < typeOptions.length; i++)
            {
                const item = typeOptions[i] as HTMLInputElement;
                item.addEventListener("change", () =>
                {
                    typeSubmit.value = item.value;

                    if (typeSubmit.form)
                    {
                        const submitEvent = document.createEvent("Event");
                        submitEvent.initEvent("submit");
                        typeSubmit.form.dispatchEvent(submitEvent);
                    }
                });
            }
        }
    }
    setupTravelForm();
    document.addEventListener("ajaxreload", (ev) =>
    {
        console.log("ajax reload");
        if ((ev.target as HTMLElement).querySelector("form.travel-time-form") != null)
        {
            setupTravelForm();
        }
    });

    const styleLinks = document.getElementsByClassName("style-switcher");
    for (let i = 0; i < styleLinks.length; i++)
    {
        styleLinks[i].addEventListener("click", (ev) =>
        {
            ev.preventDefault();

            if (styleLinks[i].classList.contains("style-default"))
            {
                SetActiveStyleSheet('Default');
            }
            else if (styleLinks[i].classList.contains("style-bow"))
            {
                SetActiveStyleSheet('High Contrast - Black On White');
            }
            else if (styleLinks[i].classList.contains("style-wob"))
            {
                SetActiveStyleSheet('High Contrast - White On Black');
            }
            else if (styleLinks[i].classList.contains("style-large"))
            {
                SetActiveStyleSheet('Large Print');
            }
        });
    }

    jQuery("a#back-to-top").click((ev) =>
    {
        ev.preventDefault();
        jQuery(document).scrollTop(0);
    })

    var scrollables = document.querySelectorAll(".waiting-graph-size-out,.table-responsive");
    for (let i = 0; i < scrollables.length; i++)
    {
        Scrollbar.init(scrollables[i] as HTMLElement, { alwaysShowTracks: true });
    }

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.map(function (popoverTriggerEl)
    {
        return new Popover(popoverTriggerEl)
    });

    const forms = document.querySelectorAll("form[data-ajax]");
    for (let i = 0; i < forms.length; i++)
    {
        AjaxFormInitialise(forms[i] as HTMLFormElement);
    }
});

function InitialiseProfessionalPopup()
{
    if (!readCookie("pronotify"))
    {
        const modal = new Modal("#health-professionals-modal");
        modal.show();
        createCookie("pronotify", "true", 365);
    }
}
(window as any).InitialiseProfessionalPopup = InitialiseProfessionalPopup;