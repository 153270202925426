import { Chart, BarController, BarElement, CategoryScale, LinearScale, Legend, Tooltip } from "chart.js";
Chart.register(BarController, BarElement, CategoryScale, LinearScale, Legend, Tooltip);

interface IGPViewData
{
    Name: string;
    WaitingFirstAppointment?: number;
    WaitingFollowupAppointment?: number;
    WaitingPlannedAdmission?: number;
    Total?: number
}

export function InitialiseGraph(elem: HTMLCanvasElement)
{
    Chart.defaults.font.family = '"Montserrat", sans-serif';
    Chart.defaults.font.size = 14;
    Chart.defaults.color = "#242424";

    const rawData = JSON.parse(elem.dataset.graph as string) as IGPViewData[];

    const graphData = [];

    if (rawData.some((item) => !!(item.WaitingFirstAppointment)))
    {
        graphData.push({
            label: "To be booked into a first appointment  ",
            data: rawData.map((item) => item.WaitingFirstAppointment ? Math.round(item.WaitingFirstAppointment) : item.WaitingFirstAppointment),
            backgroundColor: "#67c0e2",
            // stack: "trust",
            skipNull: false,
            order: 0
        });
    }

    if (rawData.some((item) => !!(item.WaitingFollowupAppointment)))
    {
        graphData.push({
            label: "Follow-up  ",
            data: rawData.map((item) => item.WaitingFollowupAppointment ? Math.round(item.WaitingFollowupAppointment) : item.WaitingFollowupAppointment),
            backgroundColor: "#92d0e9",
            // stack: "trust",
            skipNull: false,
            order: 1
        });
    }

    if (rawData.some((item) => !!(item.WaitingPlannedAdmission)))
    {
        graphData.push({
            label: "First appointment  ",
            data: rawData.map((item) => item.WaitingPlannedAdmission ? Math.round(item.WaitingPlannedAdmission) : item.WaitingPlannedAdmission),
            backgroundColor: "#67c0e2",
            // stack: "trust",
            skipNull: false,
            order: 2
        });
    }

    if (rawData.some((item) => !!(item.Total)))
    {
        graphData.push({
            label: "Total",
            data: rawData.map((item) => item.Total ? Math.round(item.Total) : item.Total),
            backgroundColor: "#7ed2ea",
            // stack: "total",
            skipNull: false,
            order: 3
        });
    }

    new Chart(elem, {
        type: "bar",
        data: {
            labels: rawData.map((item) => item.Name),
            datasets: graphData,
        },
        options: {
            indexAxis: 'y',
            scales: {
                x: {
                    beginAtZero: true,
                    stacked: false,
                    ticks: {
                        padding: 15
                    },
                    grid: {
                        color: "#707070",
                        borderWidth: 0,
                        tickLength: 0
                    }
                },
                y: {
                    grid: {
                        display: false,
                        tickLength: 60
                    }
                }
            },
            plugins: {
                legend: {
                    position: "bottom",
                    align: "start",
                    title: {
                        display: true,
                        padding: {
                            bottom: 32
                        }
                    },
                    labels: {
                        boxHeight: 20,
                        boxWidth: 20,
                        padding: 0,
                        font: {
                            weight: "500"
                        }
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function (context)
                        {
                            let label = context.dataset.label || '';
                            label = label.substring(0, label.length - 2);
                            label = " " + label;

                            if (label)
                            {
                                label += ': ';
                            }

                            if (context.parsed.x !== null)
                            {
                                label += context.parsed.x.toFixed(0) + " weeks";
                            }

                            return label;
                        }
                    }
                }
            }
        }
    });
}